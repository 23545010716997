<template>
  <div class="share-bar frcb">
    <div class="back frc" @click="$router.back()">
      <div class="back-icon">
        <img src="@/assets/images/left_arrow.png" alt="" />
      </div>
      <div class="page-title">{{ title }}</div>
    </div>
    <div class="share-icon" @click="show = true">
      <img src="@/assets/images/share_icon.png" alt="" />
    </div>
    <van-popup
      v-model="show"
      position="bottom"
      :style="{ 'min-height': '30%', background: '#FBFBFB' }"
    >
      <div class="share-list frcw">
        <div class="share-item fcc" v-for="(item, index) in list" :key="index">
          <div class="share-icon-box frcc">
            <img class="share-icon" :src="item.icon" alt="" />
          </div>
          <div class="share-title">
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="cancel frcc" @click="show = false">
        取消
      </div>
    </van-popup>
  </div>
</template>

<script>
import icon1 from "@/assets/images/share1.png";
import icon2 from "@/assets/images/share2.png";
import icon3 from "@/assets/images/share3.png";
import icon4 from "@/assets/images/share4.png";
import icon5 from "@/assets/images/share5.png";
import icon6 from "@/assets/images/share6.png";
import icon7 from "@/assets/images/share7.png";
import icon8 from "@/assets/images/share8.png";
export default {
  name: "ShareBar",
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      show: false,
      list: [
        {
          title: "新浪微博",
          icon: icon1
        },
        {
          title: "微信好友",
          icon: icon2
        },
        {
          title: "朋友圈",
          icon: icon3
        },
        {
          title: "微信收藏",
          icon: icon4
        },
        {
          title: "QQ好友",
          icon: icon5
        },
        {
          title: "QQ空间",
          icon: icon6
        },
        {
          title: "短信",
          icon: icon7
        },
        {
          title: "邮箱",
          icon: icon8
        }
      ]
    };
  }
};
</script>

<style lang="less" scoped>
.share-bar {
  position: fixed;
  width: 100vw;
  padding: 0 3vw;
  height: 13vw;
  box-sizing: border-box;
  background-color: #f4f4f4;
  .back-icon {
    width: 2vw;
    height: 4vw;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .page-title {
    margin-left: 3vw;
    font-family: PingFang-SC-Medium;
    font-size: 4vw;
    color: #404040;
  }
  .share-icon {
    width: 4vw;
    height: 4vw;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.cancel {
  height: 12vw;
  border-top: 1px solid #d7d7d7;
}

.share-list {
  padding: 4vw 7vw 4vw 7vw;
  .share-item {
    margin-right: 4vw;
    &:nth-child(3) {
      .share-icon-box {
        .share-icon {
          width: 6vw;
          height: 6vw;
        }
      }
    }
    &:nth-child(4) {
      .share-icon-box {
        .share-icon {
          width: 5vw;
          height: 6vw;
        }
      }
    }
    &:nth-child(5) {
      .share-icon-box {
        .share-icon {
          width: 5vw;
          height: 6vw;
        }
      }
    }
    &:nth-child(6) {
      .share-icon-box {
        .share-icon {
          width: 6vw;
          height: 6vw;
        }
      }
    }
    &:nth-child(7) {
      .share-icon-box {
        .share-icon {
          width: 6vw;
          height: 6vw;
        }
      }
    }
    .share-icon-box {
      width: 15vw;
      height: 15vw;
      background-color: #ffffff;
      border-radius: 4vw;

      .share-icon {
        width: 6vw;
        height: 5vw;
      }
    }
    .share-title {
      margin-top: 1vw;
      font-family: PingFang-SC-Medium;
      font-size: 3vw;
      color: #666666;
    }
  }
}
</style>
