import { axios } from "@/utils/request";

//获取新闻文章列表
export function fetchNews(params) {
  return axios({
    url: "/writings",
    method: "get",
    params
  });
}
//获取文章详情
export function fetchNewsDetails(id) {
  return axios({
    url: `/writings/getWritingInfo/${id}`,
    method: "get"
  });
}
